<template>
  <div class="container">
    <!-- banner   -->
    <Banner />
    <!-- 产品   -->
    <div class="container_product">
      <div class="container_product_top">
        <div class="container_product_top_left">
          <div class="container_product_top_left_text">Product</div>
          <el-input
            v-model="productInput"
            placeholder="Find the Perfect Product for You"
            clearable
          >
            <template #append>
              <el-button
                :icon="Search"
                @click="onLoadProduct()"
              />
            </template>
          </el-input>
        </div>
        <div class="container_product_top_buttons">
          <div
            v-for="item in productButtonList"
            :key="item.id"
            :class="[item.active === true ? 'container_product_top_buttons_item1' : 'container_product_top_buttons_item2']"
            @click="getProductListM(item.id)"
          >
            {{ item.categoryName }}
          </div>
        </div>
      </div>
      <div class="container_product_content">
        <div
          class="container_product_content_item"
          v-for="product in productList"
          :key="product.productId"
          @click="toProductDetail(product.productId)"
        >
          <img
            :src="product.productImageList && product.productImageList.length > 0 ? product.productImageList[0].imageUrl : imageUrl"
            alt=""
          >
          <div class="container_product_content_item_name">
            {{ product.productName }}
          </div>
          <!--          <div class="container_product_content_item_foot">
            <img src="../assets/images/home/foot.png" alt="">
            <div class="container_product_content_item_foot_text">
              {{ product.carbonFootprintSum }}g CO2
            </div>
          </div>-->
        </div>
      </div>
      <div class="container_product_page">
        <pagination
          v-show="productTotal > 0"
          :total="productTotal"
          layout="total,  prev, pager, next, jumper"
          v-model:page="queryProduct.pageNum"
          v-model:limit="queryProduct.pageSize"
          @pagination="onLoadProduct()"
          :auto-scroll="false"
        />
      </div>
    </div>
    <!-- foot   -->
    <Foot />
    <!-- 右下角加号 -->
    <div
      class="affix-add"
      @click="toSendCustomizeRequest"
    >
      <el-tooltip effect="light" hide-after="1" content="Send New Quotation Request" placement="top">
        <!--        <div class="affix-add_btn"><span>New Order</span></div>-->
        <el-button type="danger" plain><span>New Order</span></el-button>
      </el-tooltip>
    </div>
  </div>

</template>

<script setup name="Home">
import { ref } from '@vue/reactivity'
import { onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { Search } from '@element-plus/icons-vue'
import Pagination from '@/components/Pagination'
import { getProductList, getProductTypeList } from '@/api/product'
// 引入的页面组件
import Banner from '../components/Banner.vue'
import Foot from '../components/Foot.vue'

const router = useRouter()

const productButtonList = ref([{ id: null, categoryName: 'All', active: true }])

const imageUrl = ref('https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/co-track/goodimage.png')

const categoryList = async () => {
  const result = await getProductTypeList()
  if (result?.code === 200) {
    productButtonList.value = [{ id: null, categoryName: 'All', active: true }]
      .concat(result.data.map(category => ({ ...category, active: false })))
  }
}
// 跳转 提交公开需求
const toSendCustomizeRequest = () => {
  console.log('toSendCustomizeRequest')
  router.push({
    path: 'sendCustomizeRequest'
  })
}
// 产品分页 start
const productTotal = ref(1)
const productInput = ref('')
const productList = ref([])
const productIsEmpty = ref(false)
const queryProduct = ref()
queryProduct.value = {
  pageSize: 12,
  pageNum: 1,
  categoryId: null,
  random: '',
  productName: '',
  status: 1,
  orderByColumn: 'm.create_time',
  isAsc: 'desc'
}

const getProductListM = async (id) => {
  console.log('getProductListM s id:' + id)
  for (const o of productButtonList.value) {
    if (o.id === id) {
      o.active = true
    } else {
      o.active = false
    }
  }
  queryProduct.value.categoryId = id
  onLoadProduct()
}

const onLoadProduct = async () => {
  productList.value = []
  if (productInput != null) {
    queryProduct.value.productName = productInput
  }
  const result = await getProductList(queryProduct.value)
  if (result?.code === 200) {
    productList.value = result?.data.rows
    productTotal.value = result.data.total
    if (result.data.total > 0) {
      productIsEmpty.value = false
    } else {
      productIsEmpty.value = true
    }
  }
}
onLoadProduct()
// 产品分页 end

const toProductDetail = (productId) => {
  console.log('toProductDetail')
  router.push({
    path: 'productDetail',
    query: {
      productId,
      userType: 'buyer'
    }
  })
}

onBeforeMount(() => {
  categoryList()
})
</script>

<style lang="scss" scoped>
.dialogSample {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 16px 0;

  &_item {
    width: 80%;
    margin-bottom: 43px;
    display: flex;
    flex-direction: column;
    text-align: left;

    &_one {
      line-height: 16px;
      color: rgba(24, 24, 24, 1);
      font-size: 14px;
      text-align: left;
    }

    &_two {
      margin-top: 10px;
      line-height: 16px;
      color: rgba(24, 24, 24, 1);
      font-size: 14px;
      text-align: left;
    }

    &_three {
      line-height: 16px;
      color: rgba(39, 174, 96, 1);
      font-size: 14px;
      text-align: right;
      font-weight: bold;
    }

    &_dashed {
      margin-left: 10px;
      margin-right: 10px;
      width: 100px;
      border-top: 2px dashed var(--el-border-color);
    }
  }
}

.dialogCo2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 16px 0;

  &_item {
    width: 80%;
    margin-bottom: 43px;
    display: flex;
    align-items: center;

    img {
      flex: 1;
      margin-left: 15px;
      margin-right: 15px;
      width: 16px;
      height: 16px;
    }

    &_one {
      flex: 2;
      line-height: 16px;
      color: rgba(24, 24, 24, 1);
      font-size: 14px;
      text-align: left;
    }

    &_two {
      flex: 2;
      line-height: 16px;
      color: rgba(24, 24, 24, 1);
      font-size: 14px;
      text-align: left;
    }

    &_three {
      flex: 1;
      line-height: 16px;
      color: rgba(39, 174, 96, 1);
      font-size: 14px;
      text-align: right;
      font-weight: bold;
    }

    &_four {
      flex: 1;
      margin-left: 10px;
      line-height: 16px;
      color: rgba(36, 89, 2, 0.5);
      font-size: 12px;
      text-align: left;
    }

    &_dashed {
      flex: 5;
      margin-left: 10px;
      margin-right: 10px;
      width: 100px;
      border-top: 2px dashed var(--el-border-color);
    }
  }
}

.my-header {
  height: 80px;
  line-height: 80px;
  border-radius: 8px 8px 0px 0px;
  background-color: rgba(248, 248, 248, 1);
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;

  &_co2 {
    margin-left: 10px;
    line-height: 80px;
    color: rgba(39, 174, 96, 1);
    font-size: 20px;
    text-align: left;
    font-weight: bold;
  }

  &_unit {
    margin-left: 10px;
    line-height: 20px;
    color: rgba(39, 174, 96, 0.8);
    font-size: 14px;
    text-align: left;
  }
}

.demonstration {
  color: var(--el-text-color-secondary);
}

.container {
  position: relative;
  // width: 100%;
  // height: 100vh;
  background-color: #ffffff;
  // display: flex;
  // flex-direction: column;

  &_order {
    &_page {
      background-color: #f5f5f5;
      height: 60px;
      text-align: center;
      display: inline-block;
    }

    &_top {
      width: 1600px;
      margin: 40px auto;
      display: flex;

      &_text {
        flex: 1;
        line-height: 36px;
        color: rgba(36, 89, 2, 1);
        font-size: 28px;
        text-align: left;
        font-weight: bold;
      }

      &_buttons {
        display: flex;

        &_item1 {
          padding: 10px 15px 10px 15px;
          margin-left: 15px;
          border-radius: 4px;
          background-color: rgba(39, 174, 96, 1);
          text-align: center;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
        }

        &_item2 {
          padding: 10px 15px 10px 15px;
          margin-left: 15px;
          border-radius: 4px;
          background-color: rgba(39, 174, 96, 0.06);
          text-align: center;
          color: rgba(39, 174, 96, 1);
          font-size: 14px;
        }
      }
    }

    &_content {
      width: 1600px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;

      // width: 1200px;
      // margin: 0 auto;
      // column-count: 3;
      // column-gap: 15px;
      margin-bottom: 15px;

      &_item {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
        text-align: center;
        width: 388px;
        margin-bottom: 15px;
        border-radius: 10px;
        margin-left: 10px;

        // break-inside: avoid-column;
        &:nth-child(3n) {
          margin-right: 0;
        }

        &_todo {
          height: 80px;
          padding: 30px 30px 0 30px;
          line-height: 20px;
          border-radius: 10px 10px 0px 0px;
          background: linear-gradient(
            180deg,
            rgba(39, 174, 96, 0.1) 0%,
            rgba(39, 174, 96, 0) 100%
          );
          display: flex;

          img {
            width: 80px;
            height: 80px;
            border-radius: 4px;
            border: 1px solid rgba(39, 174, 96, 0.1);
          }

          &_right {
            margin-left: 15px;
            height: 80px;
            display: flex;
            flex-direction: column;

            &_one {
              color: rgba(24, 24, 24, 1);
              font-size: 18px;
              font-weight: 600;
              text-align: left;
              width: 220px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            &_two {
              margin-top: 6px;
              color: rgba(173, 173, 173, 1);
              font-size: 14px;
              text-align: left;
            }

            &_three {
              margin-top: 10px;
              padding: 2px 4px 2px 4px;
              border-radius: 2px;
              background-color: rgba(39, 174, 96, 0.06);
              color: rgba(36, 89, 2, 0.6);
              font-size: 14px;
              text-align: center;
            }
          }
        }

        &_emp {
          width: 100%;
          margin-top: 20px;
          line-height: 16px;
          color: rgba(36, 89, 2, 0.8);
          font-size: 14px;
          text-align: center;
        }

        &_list {
          padding: 10px 30px 20px 30px;
          display: flex;
          flex-direction: column;
          align-items: center;

          &_item {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            border-top: 1px solid rgba(240, 240, 240, 1);

            &:first-child {
              border: none;
            }

            &_top {
              width: 100%;
              display: flex;
              align-items: center;
              padding: 20px 0;

              &_left {
                flex: 1;
                display: flex;

                img {
                  width: 16px;
                  height: 16px;
                }

                &_co2 {
                  margin-left: 10px;
                  line-height: 16px;
                  color: rgba(39, 174, 96, 1);
                  font-size: 14px;
                  text-align: left;
                  font-weight: bold;
                }

                &_text1 {
                  margin-left: 5px;
                  height: 16px;
                  color: rgba(36, 89, 2, 0.5);
                  font-size: 12px;
                  text-align: left;
                }

                &_text2 {
                  height: 12px;
                  color: rgba(36, 89, 2, 0.5);
                  font-size: 10px;
                  text-align: left;
                }
              }

              &_right {
                display: flex;

                &_text {
                  line-height: 16px;
                  color: rgba(36, 89, 2, 0.6);
                  font-size: 14px;
                  text-align: right;
                  width: 150px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                img {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }

          &_line {
            margin-top: 18px;
            margin-bottom: 18px;
            width: 100%;
            height: 1px;
            background-color: rgba(240, 240, 240, 1);
            text-align: center;
          }

          &_tag {
            height: 12px;
            color: rgba(173, 173, 173, 1);
            font-size: 14px;
            text-align: left;
            font-family: Helvetica-regular;
          }
        }

        &_content {
          padding: 40px 30px 20px 30px;
          display: flex;
        }

        &_line {
          // margin-top: 0px;
          margin-bottom: 18px;
          width: 100%;
          height: 1px;
          line-height: 20px;
          background-color: rgba(240, 240, 240, 1);
          text-align: center;
        }

        &_tag {
          display: flex;
          align-items: center;
          padding-bottom: 20px;

          &_left {
            width: 50%;
            text-align: center;
            display: flex;
            justify-content: center;
            margin-left: 10px;

            img {
              width: 16px;
              height: 16px;
            }

            &_name {
              margin-left: 5px;
              line-height: 16px;
              color: rgba(36, 89, 2, 1);
              font-size: 14px;
              text-align: right;
              font-family: Helvetica-regular;
            }
          }

          &_right {
            width: 50%;
            text-align: center;
            display: flex;
            justify-content: center;

            img {
              width: 16px;
              height: 16px;
            }

            &_name {
              margin-left: 5px;
              line-height: 16px;
              color: rgba(36, 89, 2, 1);
              font-size: 14px;
              text-align: right;
              font-family: Helvetica-regular;
            }
          }
        }

        &_tag2 {
          margin: 0 auto;
          display: flex;
          text-align: center;
          align-items: center;
          padding-bottom: 20px;

          &_name {
            line-height: 16px;
            color: rgba(36, 89, 2, 1);
            font-size: 12px;
            text-align: right;
            font-family: Helvetica-regular;
          }

          &_to {
            width: 16px;
            height: 16px;
          }
        }
      }

      &_item2 {
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
        text-align: center;
        // border: 1px solid rgba(255, 255, 255, 1);
        // margin: 10px;
        // width: calc(33% - 20px);
        // height: 250px;
        width: 388px;
        margin-bottom: 15px;
        border-radius: 10px;
        break-inside: avoid-column;
        cursor: pointer;
        margin-left: 10px;

        // break-inside: avoid-column;
        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      &_item3 {
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
        text-align: center;
        width: 388px;
        height: 200px;
        break-inside: avoid-column;
        margin-bottom: 15px;
      }
    }

    &_empty {
      width: 300px;
      height: 300px;
      margin: 40px 450px 40px 450px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  // completedOrder
  .completedOrder {
    &_item2 {
      height: 200px;

      &_line {
        display: none;
      }
    }

    &_item {
      height: 200px;

      &_one {
        margin: 75px 60px 0px 75px;
        height: 12px;
        color: rgba(173, 173, 173, 1);
        font-size: 12px;
        text-align: left;
        font-family: Helvetica-regular;
      }

      &_two {
        margin: 10px 60px 0px 75px;
        display: flex;
        align-items: center;

        &_left {
          line-height: 24px;
          color: rgba(39, 174, 96, 1);
          font-size: 24px;
          text-align: left;
          font-family: Helvetica-bold;
        }

        &_right {
          margin-left: 5px;
          line-height: 16px;
          color: rgba(39, 174, 96, 1);
          font-size: 16px;
          text-align: left;
          font-family: Helvetica-regular;
        }
      }
    }
  }

  &_product {
    width: 1600px;
    margin: 80px auto;

    &_page {
      background-color: #f5f5f5;
      height: 60px;
      text-align: center;
      display: inline-block;
    }

    &_top {
      display: flex;
      align-items: center;

      &_left {
        display: flex;
        align-items: center;
        flex: 1;

        &_text {
          line-height: 36px;
          color: rgba(36, 89, 2, 1);
          font-size: 28px;
          text-align: left;
          font-weight: bold;
        }

        .el-input-group {
          margin-bottom: 0;
        }

        .el-input {
          margin-left: 50px;
          width: 350px;
          line-height: 20px;
          border-radius: 2px;
          text-align: center;
        }
      }

      &_buttons {
        display: flex;
        align-items: center;

        &_item1 {
          padding: 10px 15px 10px 15px;
          margin-left: 15px;
          border-radius: 4px;
          background-color: rgba(39, 174, 96, 1);
          text-align: center;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
        }

        &_item2 {
          padding: 10px 15px 10px 15px;
          margin-left: 15px;
          border-radius: 4px;
          background-color: rgba(39, 174, 96, 0.06);
          text-align: center;
          color: rgba(39, 174, 96, 1);
          font-size: 14px;
        }
      }
    }

    &_content {
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 30px;

      &_item {
        width: 284px;
        // height: 250px;
        line-height: 20px;
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 1);
        text-align: center;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
        padding: 10px 10px 24px 10px;
        margin-bottom: 20px;
        margin-left: 10px;

        &:nth-child(4n) {
          margin-right: 0px;
        }

        img {
          width: 284px;
          height: 265px;
          object-fit: cover;
          border-radius: 6px;
        }

        &_name {
          margin-left: 10px;
          margin-top: 20px;
          color: rgba(24, 24, 24, 1);
          font-size: 16px;
          font-weight: 600;
          text-align: left;
          font-family: Helvetica-regular;
          word-wrap: break-word;
          word-break: normal;
        }

        &_foot {
          margin-top: 15px;
          display: flex;

          img {
            margin-left: 10px;
            width: 20px;
            height: 20px;
          }

          &_text {
            margin-left: 10px;
            flex: 1;
            line-height: 20px;
            color: rgba(39, 174, 96, 1);
            font-size: 12px;
            text-align: left;
            font-family: Helvetica-regular;
          }
        }
      }
    }
  }
}

.affix-add {
  width: 80px;
  height: 80px;
  position: fixed;
  top: 715px;
  right: 80px;

  &_btn {
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    border-radius: 50%;
    font-size: 60px;
    background-color: rgba(39, 174, 96, 1);
    color: #fff;
  }

  &:hover {
    cursor: pointer;
  }
}

.SampleRequest {
  padding-left: 0px;
  padding-right: 0px;
  display: flex;

  &_left {
    margin-left: 30px;
  }

  &_right {
    margin-right: 30px;
  }
}

.SampleRequestBox {
  display: flex;
  flex-wrap: wrap;
}
</style>

<style lang="scss">
.el-dialog__header {
  padding: 0;
  margin-right: 0;
}
</style>
